import { Select } from "_components";
import { EstateLogsLogo } from "_components/Icons";
import { AppContext } from "_providers";
import { Container, MainBackdrop } from "_styles";
import { TLangOption } from "_types/common";
import { getLangOptions } from "_utils/common";
import { ReactNode, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const FormWrap = ({ children }: { children: ReactNode; }) => {
  const { language, onLangSelect } = useContext(AppContext) || {};
  const { t } = useTranslation();
  const OPTIONS = useMemo(() => getLangOptions(), [t]);

  return (
    <MainBackdrop>
      <div style={{ "width": 120, "position": `absolute`, "top": 32, "right": 32 }}>
        <Select
          value={OPTIONS.find(({ value }) => value === language)}
          options={OPTIONS}
          onChange={(selected) => {
            const lang = (selected as TLangOption).value;

            lang && onLangSelect?.(lang);
          }}
        />
      </div>

      <Container>
        <EstateLogsLogo style={{ "flexShrink": 0 }} size={38} />

        <h1>{t(`LBL_WELCOME`)}</h1>

        {children}
      </Container>
    </MainBackdrop>
  );
};

export default FormWrap;
