import { MarkupWrapBase, Text } from "_styles";
import styled from "styled-components";

export const MarkupWrap = styled(MarkupWrapBase)`
  display: flex;
  flex-direction: column;
  width: 320px;

  ${Text} {
    font-size: 24px;
    margin: 0 0 24px;
  }
`;
