import { Button, ChevronLeft, SelfValidatedInput } from "_components";
import FormWrap from "_components/FormWrap";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { useLazyResetPasswordQuery } from "_screens/SignIn/SignIn.queries";
import { Form, Line, LinkBig, LinksWrap, Text } from "_styles";
import { TRANSLATION_COMPONENTS } from "_utils/constants";
import isEmpty from "lodash.isempty";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { passwordlessLoginFormResolver } from "utils/resolvers";

import { MarkupWrap } from "./ResetPassword.styles";

type TResetPasswordFormData = {
  email: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const [emailCached, setEmailCached] = useState<string | ``>(``);
  const { redirectionURL = ``, language = `eng` } = useContext(AppContext) || {};
  const { t } = useTranslation();

  const [resetPasswordTrigger, { isError, isFetching, isLoading, isSuccess }] =
    useLazyResetPasswordQuery();

  const isEmailSent = emailCached && isSuccess;

  const handleRedirect = (to: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(to);
  };
  const {
    handleSubmit,
    "formState": { "errors": formErrors },
    control,
    trigger,
    reset
  } = useForm<TResetPasswordFormData>({
    "defaultValues": {
      "email": ``
    },
    "resolver": passwordlessLoginFormResolver()
  });

  const onSubmit = handleSubmit(({ email }) => {
    setEmailCached(email);

    resetPasswordTrigger({ email, "redirect": redirectionURL, language });
  });

  useEffect(() => {
    if (!isError && isSuccess && !isFetching) {
      reset();
    }
  }, [isError, isFetching, isSuccess, reset]);

  return (
    <FormWrap>
      {!isEmailSent && (
        <MarkupWrap>
          <LinksWrap>
            <LinkBig
              className="active"
              href={ERoutes.resetPassword}
              onClick={handleRedirect(ERoutes.resetPassword)}
            >
              {t(`LBL_SIGN-IN`)}
            </LinkBig>

            <LinkBig href={ERoutes.signUp} onClick={handleRedirect(ERoutes.signUp)}>
              {t(`LBL_CREATE-ACCOUNT`)}
            </LinkBig>
          </LinksWrap>

          <Text style={{ "marginBottom": 40, "fontSize": 16 }}>
            {t(`MSG_FORGOT-YOUR-PASSWORD`)}

            <br />

            {t(`MSG_DONT-WORRY-ENTER-EMAIL`)}
          </Text>

          <Form onSubmit={onSubmit}>
            <Controller
              name="email"
              control={control}
              render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
                <SelfValidatedInput
                  disabled={isLoading || isFetching}
                  id="name"
                  placeholder={t(`MSG_ENTER-YOUR-EMAIL`)}
                  onBlur={() => {
                    fieldProps.value && trigger(fieldProps.name);
                    onBlur?.();
                  }}
                  label={t(`LBL_EMAIL`)}
                  errorMessage={error?.message}
                  onChange={(value) => {
                    onChange(value.trim());
                  }}
                  {...fieldProps}
                />
              )}
            />

            <Button
              size="medium"
              type="submit"
              fullWidth
              disabled={isLoading || isFetching || !isEmpty(formErrors)}
              label={t(`LBL_EMAIL-ME-INSTRUCTIONS`)}
              loadingLabel={t(`LBL_SENDING`)}
              loading={isLoading || isFetching}
            />
          </Form>

          <Line className="solid" />

          <Link className="lnk-back" to={ERoutes.signIn}>
            <ChevronLeft />

            {t(`LBL_BACK-TO-QUICK-ENTRY`)}
          </Link>
        </MarkupWrap>
      )}

      {isEmailSent && (
        <MarkupWrap>
          <Text style={{ "fontSize": 16 }}>
            <Trans
              i18nKey="MSG_WE-SENT-YOU-EMAIL-RESET-PASSWORD"
              values={{ "email": emailCached }}
              components={TRANSLATION_COMPONENTS}
            />
          </Text>

          <Button
            size="medium"
            fullWidth
            disabled={isLoading || isFetching || !isEmpty(formErrors)}
            label={t(`LBL_RESEND-ME-INSTRUCTIONS`)}
            loadingLabel={t(`LBL_SENDING`)}
            loading={isLoading || isFetching}
            onClick={() => {
              resetPasswordTrigger({
                "email": emailCached,
                "redirect": redirectionURL,
                language
              });
            }}
          />

          <Line className="solid" />

          <Link className="lnk-back" to={ERoutes.signIn}>
            <ChevronLeft />

            {t(`LBL_BACK-TO-QUICK-ENTRY`)}
          </Link>
        </MarkupWrap>
      )}
    </FormWrap>
  );
}
