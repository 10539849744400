export enum ERoutes {
  root = ``,
  signIn = `/sign-in`,
  signUp = `/sign-up`,
  passwordlessSignIn = `/sign-in/passwordless`,
  resetPassword = `/reset-password`,
  createPassword = `/create-password`,

  signUpConfirm = `/sign-up/confirm`,
  passwordlessSignInConfirm = `/sign-in/passwordless/confirm`
}

export enum EApiRoutes {
  auth = `authentication`,
  passwordlessAuth = `authentication/passwordless`,
  passwordlessAuthConfirm = `authentication/passwordless/confirm`,
  register = `users/registration`,
  resetPassword = `users/reset-password`,
  confirmPassword = `users/reset-password/confirm`
}
