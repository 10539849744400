import { Button, Dialog, SelfValidatedInput } from "_components";
import { TDialogMethods } from "_components/Dialog/Dialog";
import FormWrap from "_components/FormWrap";
import { DEFAULT_TIMEZONE, TIMEZONES } from "_constants/timezones";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { Form, LinkBig, LinksWrap, LinkUsual, MarkupWrapBase } from "_styles";
import { TTimezone } from "_types/common";
import dayjs from "dayjs";
import isEmpty from "lodash.isempty";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getDialogTitle } from "utils/common";
import { signUpFormResolver } from "utils/resolvers";
import { getPasswordRules, getUsernameRules } from "utils/rules";

import { useSignUpMutation } from "./SignUp.queries";
import { Terms } from "./SignUp.styles";

export type TSignUpFormData = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const dialogRef = useRef<TDialogMethods>(null);
  const { redirectionURL = ``, language = `eng` } = useContext(AppContext) || {};
  const [signUpTrigger, { isError, isLoading, error }] = useSignUpMutation();
  const { t } = useTranslation();
  const {
    handleSubmit,
    "formState": { "errors": formErrors },
    control,
    trigger,
    reset
  } = useForm<TSignUpFormData>({
    "defaultValues": {
      "username": ``,
      "email": ``,
      "password": ``,
      "passwordConfirm": ``
    },
    "resolver": signUpFormResolver(),
    "reValidateMode": `onChange`
  });

  const passwordRules = useMemo(() => getPasswordRules(), [t]);
  const usernameRules = useMemo(() => getUsernameRules(), [t]);

  const onSubmit = handleSubmit((data) => {
    const guessTimezone = dayjs.tz.guess();
    const isTimezoneWithinAllowed = TIMEZONES.includes(guessTimezone as TTimezone);

    signUpTrigger({
      language,
      ...data,
      "redirect": redirectionURL,
      "timezone": isTimezoneWithinAllowed ? guessTimezone as TTimezone : DEFAULT_TIMEZONE
    }).then((resp) => {
      if (!(`error` in resp)) {
        dialogRef.current?.open({
          "title": t(`TITLE-ACCOUNT_VERIFICATION`),
          "text": t(`MSG-WE_SENT_THE_INSTRUCTIONS`, { "email": data.email })
        });

        reset();
      }
    });
  });

  const handleRedirect = (to: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(to);
  };

  useEffect(() => {
    if (!isLoading && isError && error) {
      if (`data` in error) {
        const errorId = error.data.error.id;
        const isBackendError = error.status === 500;

        dialogRef.current?.open({
          "title": isBackendError ? t(`TITLE-SOMETHING_WENT_WRONG`) : getDialogTitle(errorId),
          "text": isBackendError ? t(`MSG-SOMETHING_WENT_WRONG`) : t(errorId)
        });
      }
    }
  }, [error, isError, isLoading, reset, t]);

  return (
    <FormWrap>
      <MarkupWrapBase>
        <LinksWrap>
          <LinkBig href={ERoutes.signIn} onClick={handleRedirect(ERoutes.signIn)}>
            {t(`LBL_SIGN-IN`)}
          </LinkBig>

          <LinkBig
            className="active"
            href={ERoutes.signUp}
            onClick={handleRedirect(ERoutes.signUp)}
          >
            {t(`LBL_CREATE-ACCOUNT`)}
          </LinkBig>
        </LinksWrap>

        <Form onSubmit={onSubmit} id="login-form">
          <Controller
            name="username"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="username"
                placeholder={t(`LBL_ENTER-YOUR-USERNAME`)}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                label={t(`LBL_USERNAME`)}
                errorMessage={error?.message}
                rules={usernameRules}
                autoComplete="new-password"
                { ...fieldProps }
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="name"
                placeholder={t(`MSG_ENTER-YOUR-EMAIL`)}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                label={t(`LBL_EMAIL`)}
                errorMessage={error?.message}
                autoComplete="new-password"
                { ...fieldProps }
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="password-input"
                type="password"
                placeholder={t(`LBL_YOUR-PASSWORD-PLACEHOLDER`)}
                label={t(`LBL_PASSWORD`)}
                rules={passwordRules}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                errorMessage={error?.message}
                autoComplete="new-password"
                { ...fieldProps }
              />
            )}
          />

          <Controller
            name="passwordConfirm"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="password-confirm"
                type="password"
                placeholder={t(`MSG_CONFIRM-YOUR-PASSWORD`)}
                label={t(`LBL_PASSWORD`)}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                errorMessage={error?.message}
                autoComplete="new-password"
                { ...fieldProps }
              />
            )}
          />
        </Form>

        <Terms>
          <Trans
            i18nKey="MSG_BY-SIGNING-UP-YOU-AGREED-WITH-TERMS-OF-SERVICE"
            components={{
              "hyperlink": (
                <LinkUsual
                  href="https://www.estatelogs.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                />
              )
            }}
          />
        </Terms>

        <Button
          form="login-form"
          size="medium"
          type="submit"
          fullWidth
          onClick={onSubmit}
          disabled={isLoading || !isEmpty(formErrors)}
          label={t(`LBL-CREATE-AN-ACCOUNT`)}
          loading={isLoading}
        />
      </MarkupWrapBase>

      <Dialog
        ref={dialogRef}
        actionButtonClick={() => {
          dialogRef.current?.close();
          navigate(ERoutes.signIn);
        }}
      />
    </FormWrap>
  );
};

export default SignUp;
