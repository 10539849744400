import styled from "styled-components";

export const LinkUsual = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.link.usual.text};
  text-decoration: none;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-top: 29px;
  margin-bottom: 40px;

  &.disabled {
    color: #b5b9bf;
  }
`;

export const LinkBig = styled.a`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.link.big.text};
  text-decoration: none;
  position: relative;
  padding: 0 9px;

  &.simple {
    color: ${({ theme }) => theme.link.big.simple};
    position: relative;
  }

  &.active {
    color: ${({ theme }) => theme.link.big.simple};
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -12px;
      background-color: ${({ theme }) => theme.link.big.simple};
      border-radius: 4px;
    }
  }
`;

export const LinksWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 60px 11px 60px;
  border-bottom: 1px solid #d1d5dc;
  margin-bottom: 30px;
`;

export const Text = styled.p<{ additional?: boolean; disabled?: boolean; }>`
  font-style: normal;
  font-weight: 400;
  font-size: 13.8px;
  line-height: 150%;
  color: ${({ theme }) => theme.common.text};
  margin: 0;

  &.additional {
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
  }

  &.disabled {
    color: ${({ theme }) => theme.common.disabled};
  }
`;

export const MainBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.common.bg};
  padding-top: 32px;
  padding-bottom: 32px;
  overflow-y: auto;
`;

export const ConfirmationScreenBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #283148;
  display: flex;
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #f6f6f9;
  }

  ${Text} {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
`;

export const Container = styled.div`
  max-width: 500px;
  background-color: ${({ theme }) => theme.container.bg};
  padding: 40px 36px;
  color: ${({ theme }) => theme.container.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 9px rgba(95, 94, 94, 0.2);
  border-radius: 8px;

  h1 {
    font-weight: 500;
  }
`;

export const Form = styled.form`
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #485164;
  margin-top: 90px;
  margin-bottom: 17px;
  width: 100%;

  &.solid {
    &:before,
    &:after {
      content: "";
      height: 1px;
      width: 50%;
      background-color: #d1d5dc;
      display: inline-block;
    }
  }

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: 45%;
    background-color: #d1d5dc;
    display: inline-block;
  }
`;

export const MarkupWrapBase = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  .lnk-back {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.common.text};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 14px;
    align-self: center;
    svg {
      color: ${({ theme }) => theme.button.bg};
    }
  }
`;
