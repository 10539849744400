import React from 'react';
import { Provider } from 'react-redux';

import { store } from '../../state';

export interface IStateProvider {
  children: JSX.Element;
}

export default function StateProvider({ children }: IStateProvider) {
  return (
    <Provider {...{ store }}>
      {children}
    </Provider>
  );
}
