import { Form, Line, LinkUsual, MarkupWrapBase, Text } from "_styles";
import styled from "styled-components";

export const MarkupWrap = styled(MarkupWrapBase)`
  ${Form} {
    margin-bottom: 24px;
  }

  ${Text} {
    margin-bottom: 24px;
  }

  ${Line} {
    align-self: center;
    margin-bottom: 30px;
  }

  ${LinkUsual} {
    font-size: 12px;
    line-height: 25px;

    &:after {
      bottom: -1px;
    }
  }
`;
