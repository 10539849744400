import { Spinner } from "_components/Icons";
import { classNames } from "_utils/common";
import React from "react";

import { StyledButton } from "./Button.styles";

type TButton = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: `big` | `medium` | `small`;
  variant?: `default` | `outline`;
  fullWidth?: boolean;
  label: string;
} & ({
  loading: boolean;
  loadingLabel?: string;
} | {
  loading?: undefined;
  loadingLabel?: undefined;
});

const Button = ({
  size = `medium`,
  type = `button`,
  variant = `default`,
  fullWidth,
  className = ``,
  label,
  loading,
  loadingLabel = `Processing`,
  ...buttonProps
}: TButton) => (
  <StyledButton
    type={type}
    className={classNames({
      [className]: !!className,
      [size]: true,
      [variant]: variant !== `default`,
      "full-width": !!fullWidth,
      loading
    })}
    {...buttonProps}
  >
    {loading && (
      <Spinner
        style={{
          "animation": `spin 2000ms linear infinite`
        }}
        size={19}
      />
    )}

    {loading ? loadingLabel : label}
  </StyledButton>
);

export default Button;
