import { Form, Line, LinkBig, LinkUsual, MarkupWrapBase } from "_styles";
import styled from "styled-components";

export const Footer = styled.footer`
  white-space: nowrap;

  ${LinkBig}:after {
    height: 2px;
    bottom: -1px;
    border-radius: 2px;
  }
`;

export const MarkupWrap = styled(MarkupWrapBase)`
  ${Form} {
    margin-bottom: 31px;
  }

  & > ${LinkUsual} {
    display: inline-block;
    margin: 0 0 31px auto;
    align-self: end;
  }

  ${Line} {
    margin: 48px auto 36px;
  }
`;

export const H2Styled = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
`;
