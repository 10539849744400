import React, { ReactNode } from "react";

import useApp, { TAppHook } from "./useApp";

export type TContextType = TAppHook;

export const AppContext = React.createContext<TContextType | null>(null);

export const AppContextProvider = ({ children }: { children: ReactNode; }) => {
  const appMethods = useApp();

  return (
    <AppContext.Provider value={appMethods}>{children}</AppContext.Provider>
  );
};
