import { Button, Dialog, SelfValidatedInput } from "_components";
import { TDialogMethods } from "_components/Dialog/Dialog";
import FormWrap from "_components/FormWrap";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { useLazyConfirmPasswordQuery } from "_screens/SignIn/SignIn.queries";
import { Form, Text } from "_styles";
import isEmpty from "lodash.isempty";
import { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getDialogTitle } from "utils/common";
import { createPasswordFormResolver } from "utils/resolvers";
import { getPasswordRules } from "utils/rules";

import { MarkupWrap } from "./CreatePassword.styles";

type TCreatePasswordFormData = {
  password: string;
  passwordConfirm: string;
};

const CreatePassword = () => {
  const dialogRef = useRef<TDialogMethods>(null);
  const [confirmPasswordTrigger, { isLoading, isError, error }] = useLazyConfirmPasswordQuery();
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get(`token`);
  const navigate = useNavigate();

  const {
    handleSubmit,
    "formState": { "errors": formErrors },
    control,
    trigger,
    reset
  } = useForm<TCreatePasswordFormData>({
    "defaultValues": {
      "password": ``,
      "passwordConfirm": ``
    },
    "resolver": createPasswordFormResolver()
  });

  const passwordRules = useMemo(() => getPasswordRules(), [t]);

  const onSubmit = handleSubmit((data) => {
    confirmPasswordTrigger({
      ...data,
      "token": token!
    }).then(({ error }) => {
      if (!error) {
        reset();

        navigate(ERoutes.signIn, {
          "replace": true
        });
      }
    });
  });

  useEffect(() => {
    if (!isLoading && isError && error) {
      if (`data` in error) {
        const errorId = error.data.error.id;
        const isBackendError = error.status === 500;

        dialogRef.current?.open({
          "title": isBackendError ? t(`TITLE-SOMETHING_WENT_WRONG`) : getDialogTitle(errorId),
          "text": isBackendError ? t(`MSG-SOMETHING_WENT_WRONG`) : t(errorId)
        });
      }
    }
  }, [error, isError, isLoading, reset, t]);

  return (
    <FormWrap>
      <MarkupWrap>
        <Text>{t(`LBL_CREATE-NEW-PASSWORD`)}</Text>

        <Form onSubmit={onSubmit}>
          <Controller
            name="password"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="password-input"
                type="password"
                placeholder={t(`LBL_YOUR-PASSWORD-PLACEHOLDER`)}
                label={t(`LBL_PASSWORD`)}
                rules={passwordRules}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                errorMessage={error?.message}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                {...fieldProps}
              />
            )}
          />

          <Controller
            name="passwordConfirm"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="password-confirm"
                type="password"
                placeholder={t(`MSG_CONFIRM-YOUR-PASSWORD`)}
                label={t(`LBL_PASSWORD`)}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                errorMessage={error?.message}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                {...fieldProps}
              />
            )}
          />

          <Button
            size="medium"
            type="submit"
            fullWidth
            disabled={isLoading || !isEmpty(formErrors)}
            label={t(`LBL_DONE`)}
            loading={isLoading}
          />
        </Form>
      </MarkupWrap>

      <Dialog
        ref={dialogRef}
        actionButtonClick={() => {
          dialogRef.current?.close();
        }}
      />
    </FormWrap>
  );
};

export default CreatePassword;
