import { classNames } from "_utils/common";
import React, { forwardRef, useMemo, useState } from "react";
import { useTheme } from "styled-components";

import {
  CheckSign,
  CircleXmark,
  ExclamationSign,
  Eye,
  EyeCrossed,
  InfoSign
} from "../Icons";
import {
  Container,
  ErrorPlate,
  InputLabel,
  ValidationPlate,
  ValidationProgress
} from "./SelfValidatedInput.styles";
import { TRule, TSelfValidatedInput } from "./SelfValidatedInput.types";

export function SelfValidatedInput(props: TSelfValidatedInput, ref: React.Ref<HTMLInputElement>) {
  const {
    id,
    value = ``,
    onChange,
    onBlur,
    onFocus,
    errorMessage,
    label,
    type = `text`,
    placeholder,
    rules,
    ...inputProps
  } = props;
  const theme = useTheme();
  const [passwordVisible, setPasswordVisibility] = useState(false);
  const [focusWithin, setFocusWithin] = useState(false);
  const isPassword = type === `password`;
  const hasError = Boolean(errorMessage);
  const hasRules = rules && Array.isArray(rules) && rules.length;
  const rulesCheckout = useMemo<(TRule & { pass: boolean; })[]>(() => {
    if (!hasRules) {
      return [];
    }

    return rules.map(({ test, ...rest }) => ({
      ...rest,
      test,
      "pass": test(value)
    }));
  }, [hasRules, rules, value]);
  const progress = useMemo<number>(() => {
    if (!isPassword || !hasRules) { return 0; }

    const rulesPassed = rulesCheckout.filter(({ pass }) => pass);

    return (100 / (rules.length)) * (rulesPassed.length);
  }, [hasRules, isPassword, rules?.length, rulesCheckout]);

  return (
    <div>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

      <Container
        className={classNames({
          "focused": focusWithin,
          "error": hasError,
          "empty": !value && !focusWithin
        })}
      >
        <input
          ref={ref}
          onFocus={(event) => {
            setFocusWithin(true);

            onFocus?.(event);
          }}
          onBlur={(event) => {
            setFocusWithin(false);

            onBlur?.(event);
          }}
          type={isPassword && passwordVisible ? `text` : type}
          style={{
            "paddingRight": isPassword ? 45 : 16
          }}
          onChange={(event) => onChange?.(event.target.value)}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          placeholder={focusWithin ? `` : placeholder}
          {...{
            id,
            value,
            ...inputProps
          }}
        />

        {hasRules && (
          <ValidationPlate>
            <ul>
              {rulesCheckout.map(({ message, pass }) => (
                <li
                  key={message}
                  className={classNames({
                    pass
                  })}
                >
                  {pass ? <CheckSign size={16} /> : <InfoSign size={16} />}

                  <span>{message}</span>
                </li>
              ))}
            </ul>

            {isPassword && <ValidationProgress>
              <span>Strength</span>

              <progress id="strength" max="100" value={progress}>
                {`${progress}%`}
              </progress>
            </ValidationProgress>}
          </ValidationPlate>
        )}

        {!focusWithin && hasError && (
          <ErrorPlate>
            <ExclamationSign size={16} />

            <span>{errorMessage}</span>
          </ErrorPlate>
        )}

        {isPassword && (
          <button
            type="button"
            onClick={() => {
              setPasswordVisibility(!passwordVisible);
            }}
          >
            {passwordVisible ? <Eye size={24} /> : <EyeCrossed size={24} />}
          </button>
        )}

        {!isPassword && value && (
          <button
            type="button"
            className="btn-clear"
            onClick={() => {
              onChange?.(``);
            }}
          >
            <CircleXmark size={16} color={theme.input.icon} />
          </button>
        )}
      </Container>
    </div>
  );
}

export default forwardRef(SelfValidatedInput);
