import "./assets/styles/fonts.css";
import "./assets/styles/general.css";

import dayjs from "dayjs";
import tz from 'dayjs/plugin/timezone';
import React from "react";
import { createRoot } from "react-dom/client";

import {
  RouterProvider,
  StateProvider,
  StyleProvider,
  TranslationsProvider
} from "./providers";

dayjs.extend(tz);

createRoot(document.getElementById(`root`)!).render(
  <React.StrictMode>
    <StateProvider>
      <StyleProvider>
        <TranslationsProvider>
          <RouterProvider />
        </TranslationsProvider>
      </StyleProvider>
    </StateProvider>
  </React.StrictMode>
);
