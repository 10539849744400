/* eslint-disable @typescript-eslint/ban-types */
import { EApiRoutes } from "_providers/RouterProvider/RouterProvider.types";
import {
  TConfirmPasswordPayload,
  TPasswordlessSignInConfirmResponse,
  TSignInPayload,
  TSignInResponse
} from "_types/apiAuthentication";
import { postConfig } from "_utils/requests";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import { TErrorApiResponse, TLangCode } from "types/common";
import { baseUrl } from "utils/common";

export const signInAPI = createApi({
  "reducerPath": `signIn`,
  "baseQuery": fetchBaseQuery({ baseUrl }) as BaseQueryFn<
    FetchArgs | string,
    unknown,
    FetchBaseQueryError & { status: number; data: TErrorApiResponse; },
    {},
    FetchBaseQueryMeta
  >,
  "endpoints": (builder) => ({
    "signIn": builder.query<TSignInResponse, TSignInPayload>({
      "query": (data) => ({
        "url": `${EApiRoutes.auth}`,
        "body": data,
        ...postConfig,
        "validateStatus": (response) => response.status === 200
      }),
      "transformResponse": (response: { data: TSignInResponse; }) => response?.data
    }),

    "passwordlessSignIn": builder.query<void, { email: string; redirect: string; language: TLangCode; }>({
      "query": (data) => ({
        "url": `${EApiRoutes.passwordlessAuth}`,
        "body": data,
        ...postConfig,
        "validateStatus": (response) => response.status === 204
      })
    }),

    "passwordlessSignInConfirm": builder.query<TPasswordlessSignInConfirmResponse, { token: string; }>(
      {
        "query": (data) => ({
          "url": `${EApiRoutes.passwordlessAuthConfirm}`,
          "body": data,
          ...postConfig,
          "validateStatus": (response) => response.status === 200
        }),
        "transformResponse": (response: { data: TPasswordlessSignInConfirmResponse; }) => response?.data
      }
    ),

    "resetPassword": builder.query<void, { email: string; redirect: string; language: TLangCode; }>({
      "query": (data) => ({
        "url": `${EApiRoutes.resetPassword}`,
        "body": data,
        ...postConfig,
        "validateStatus": (response) => response.status === 204
      })
    }),

    "confirmPassword": builder.query<void, TConfirmPasswordPayload>({
      "query": (data) => ({
        "url": `${EApiRoutes.confirmPassword}`,
        "body": data,
        ...postConfig,
        "validateStatus": (response) => response.status === 204
      })
    })
  })
});

export const {
  useSignInQuery,
  useLazySignInQuery,
  usePasswordlessSignInQuery,
  useLazyPasswordlessSignInQuery,
  useResetPasswordQuery,
  useLazyResetPasswordQuery,
  useConfirmPasswordQuery,
  useLazyConfirmPasswordQuery,
  useLazyPasswordlessSignInConfirmQuery
} = signInAPI;
