export const TIMEZONES = [
  `Europe/Amsterdam`,
  `Europe/Andorra`,
  `Europe/Athens`,
  `Europe/Belfast`,
  `Europe/Belgrade`,
  `Europe/Berlin`,
  `Europe/Bratislava`,
  `Europe/Brussels`,
  `Europe/Bucharest`,
  `Europe/Budapest`,
  `Europe/Chisinau`,
  `Europe/Copenhagen`,
  `Europe/Dublin`,
  `Europe/Gibraltar`,
  `Europe/Guernsey`,
  `Europe/Helsinki`,
  `Europe/Isle_of_Man`,
  `Europe/Istanbul`,
  `Europe/Jersey`,
  `Europe/Kaliningrad`,
  `Europe/Kiev`,
  `Europe/Lisbon`,
  `Europe/Ljubljana`,
  `Europe/London`,
  `Europe/Luxembourg`,
  `Europe/Madrid`,
  `Europe/Malta`,
  `Europe/Mariehamn`,
  `Europe/Minsk`,
  `Europe/Monaco`,
  `Europe/Moscow`,
  `Europe/Nicosia`,
  `Europe/Oslo`,
  `Europe/Paris`,
  `Europe/Podgorica`,
  `Europe/Prague`,
  `Europe/Riga`,
  `Europe/Rome`,
  `Europe/Samara`,
  `Europe/San_Marino`,
  `Europe/Sarajevo`,
  `Europe/Simferopol`,
  `Europe/Skopje`,
  `Europe/Sofia`,
  `Europe/Stockholm`,
  `Europe/Tallinn`,
  `Europe/Tirane`,
  `Europe/Tiraspol`,
  `Europe/Uzhgorod`,
  `Europe/Vaduz`,
  `Europe/Vatican`,
  `Europe/Vienna`,
  `Europe/Vilnius`,
  `Europe/Volgograd`,
  `Europe/Warsaw`,
  `Europe/Zagreb`,
  `Europe/Zaporozhye`,
  `Europe/Zurich`
] as const;

export const DEFAULT_TIMEZONE = `Europe/Stockholm`;
