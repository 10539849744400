/* eslint-disable @typescript-eslint/ban-types */
import { EApiRoutes } from "_providers/RouterProvider/RouterProvider.types";
import { TSignUpPayload } from "_types/apiAuthentication";
import { postConfig } from "_utils/requests";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import { TErrorApiResponse } from "types/common";
import { baseUrl } from "utils/common";

export const signUpAPI = createApi({
  "reducerPath": `signUp`,
  "baseQuery": fetchBaseQuery({ baseUrl }) as BaseQueryFn<
    FetchArgs | string,
    unknown,
    FetchBaseQueryError & { status: number; data: TErrorApiResponse; },
    {},
    FetchBaseQueryMeta
  >,
  "endpoints": (builder) => ({
    "signUp": builder.mutation<void, TSignUpPayload>({
      "query": (data) => ({
        "url": `${EApiRoutes.register}`,
        "body": data,
        ...postConfig,
        "validateStatus": (resp) => resp.status === 201
      })
    }),

    "confirmUser": builder.query<void, string>({
      "query": (token) => ({
        "url": `/users/registration/confirm`,
        ...postConfig,
        "includeAuthentication": false,
        "body": {
          token
        }
      })
    })
  })
});

export const { useSignUpMutation, useConfirmUserQuery, useLazyConfirmUserQuery } = signUpAPI;
