import { TTranslationKeys } from "_providers/Translations/Translations";
import i18next from "i18next";

export const RE_USERNAME_NO_PERIOND_AT_START_OR_END = /^[^.].*[^.]$/;
export const RE_USERNAME = /^[0-9a-zA-Z_.\-ÅåÄäÖöŠšŽžÆæØø]*$/;
export const RE_USERNAME_NO_CONSECUTIVE_PERIODS = /^(?!.*?\.\.).*?$/;

export const RE_PASSWORD_UPPERCASE = /[A-ZÅåÄÖŠŽÆØ]/;

export const getPasswordRules = () => [
  {
    "test": (value: string) => value.length >= 8,
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-CONTAIN-AT-LEAST-CHARACTERS`, {
      "count": 8
    })
  },
  {
    "test": (value: string) => {
      const re = /[0-9]/;

      return !!value && re.test(value);
    },
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-CONTAIN-AT-LEAST-NUMBER`, { "count": 1 })
  },
  {
    "test": (value: string) => !!value && RE_PASSWORD_UPPERCASE.test(value),
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-CONTAIN-AT-LEAST-UPPERCASE-LETTER`, {
      "count": 1
    })
  }
];

export const getUsernameRules = () => [
  {
    "test": (value: string) => value?.length >= 5 && value?.length <= 20,
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-BE-BETWEEN-CHARACTERS-LONG`, {
      "min": 5,
      "max": 20
    })
  },
  {
    "test": (value: string) => !!value && RE_USERNAME.test(value),
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-ONLY-CONTAIN-SYMBOLS`)
  },
  {
    "test": (value: string) => !!value && RE_USERNAME_NO_PERIOND_AT_START_OR_END.test(value),
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-NOT-START-OR-END-WITH-PERIOD`)
  },
  {
    "test": (value: string) => !!value && RE_USERNAME_NO_CONSECUTIVE_PERIODS.test(value),
    "message": i18next.t<string, TTranslationKeys>(`MSG_MUST-NOT-CONTAIN-CONSECUTIVE-PERIODS`)
  }
];
