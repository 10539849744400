import { Portal } from "_components";
import Button from "_components/Button";
import { Cross, Profile } from "_components/Icons";
import { classNames } from "_utils/common";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import { Backdrop } from "./Dialog.styles";

type TOpenMethod = { text: string; title?: string; };

export type TDialogMethods = {
  close: () => void;
  open: (data: TOpenMethod) => void;
};

export type TDialogProps = {
  onClose?: () => void;
} & (
  | {
    actionButtonClick: () => void;
    actionButtonText?: string;
  }
  | {
    actionButtonClick?: never;
    actionButtonText?: never;
  }
);

const Dialog = forwardRef<TDialogMethods, TDialogProps>(
  ({ actionButtonClick, actionButtonText, onClose }, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [fadeOutPass, setFadeOutPass] = useState<boolean>(true);
    const { t } = useTranslation();

    const [text, setText] = useState(``);
    const [title, setTitle] = useState(``);

    const closeDialog = () => {
      onClose?.();
      setIsVisible(false);
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === `Escape`) {
        setIsVisible(false);
      }
    };

    useImperativeHandle(ref, () => ({
      "close": closeDialog,
      "open": ({ text, title }) => {
        setText(text);
        setTitle(title || ``);

        setFadeOutPass(false);
        setIsVisible(true);
      }
    }));

    useEffect(() => {
      isVisible && window.addEventListener(`keydown`, handleKeydown);

      return () => {
        window.removeEventListener(`keydown`, handleKeydown);
      };
    });

    if (fadeOutPass || (!isVisible && fadeOutPass)) {
      return null;
    }

    return (
      <Portal targetEl={document.body}>
        <Backdrop
          onAnimationEnd={({ animationName }) => {
            if (animationName === `backdrop-fadeout`) {
              setFadeOutPass(true);
            }
          }}
          className={classNames({
            "hide": !isVisible
          })}
          onClick={closeDialog}
        >
          <div
            role="dialog"
            aria-labelledby="dialogTitle"
            aria-describedby="dialogDesc"
            className={classNames({
              "hide": !isVisible
            })}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <header>
              <button className="btn-close" onClick={closeDialog}>
                <Cross size={10} />
              </button>

              <Profile size={59} />

              <h3 id="dialogTitle">{title}</h3>
            </header>

            <main id="dialogDesc">{text}</main>

            {actionButtonClick && (
              <footer>
                {actionButtonClick && (
                  <Button
                    onClick={actionButtonClick}
                    fullWidth
                    label={actionButtonText || t(`LBL_CLOSE`)}
                  />
                )}
              </footer>
            )}
          </div>
        </Backdrop>
      </Portal>
    );
  }
);

Dialog.displayName = `Dialog`;

export default Dialog;
