// import { useRedux } from "_hooks";
import React, { ReactElement, ReactNode } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

// import { EThemes } from "./StyleProvider.types";
import lightTheme from "./theme.light";
// import lightTheme from "./theme.light";

export interface IStyleProvider {
  children: ReactNode;
}

const GlobalStyles = createGlobalStyle`
  html {
    overflow: hidden;
    height: 100%;
    font-family: 'inter-ui';
  }
`;

// const getSystemTheme = () =>
//   window?.matchMedia(`(prefers-color-scheme: dark)`).matches
//     ? EThemes.dark
//     : EThemes.light;

function StylesProvider({ children }: IStyleProvider): ReactElement {
  // const { useSelector } = useRedux();
  // const theme =
  //   useSelector((state) => state.styleProvider.theme) === EThemes.dark
  //     ? darkTheme
  //     : lightTheme;

  return (
    <ThemeProvider {...{ theme: lightTheme }}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

export default StylesProvider;
