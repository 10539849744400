import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EThemes, IStyleProviderState } from './StyleProvider.types';

const initialState: IStyleProviderState = {
  "theme": EThemes.light
};

export const styleProviderState = createSlice({
  "name": `styleProvider`,
  initialState,
  "reducers": {
    "setTheme": (state, action: PayloadAction<EThemes>) => {
      state.theme = action.payload;
    }
  }
});

export const { setTheme } = styleProviderState.actions;

export default styleProviderState;
