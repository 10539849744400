import { LinkUsual } from "_styles";
import styled from "styled-components";

export const Terms = styled.p`
  font-weight: 400;
  line-height: 25px;
  font-size: 16px;
  margin: 32px 0 24px;
  color: ${({ theme }) => theme.common.text};

  ${LinkUsual} {
    font-size: 16px;
    line-height: 25px;

    &:after {
      bottom: 0;
    }
  }
`;
