/* eslint-disable consistent-return */
import { DataProcessingMessage } from "_components";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { useLazyPasswordlessSignInConfirmQuery } from "_screens/SignIn/SignIn.queries";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

export default function PasswordlessSignInConfirm() {
  const navigate = useNavigate();
  const { redirectionURL = ``, token, setTokenCookie } = useContext(AppContext) || {};
  const [confirmToken, { isUninitialized }] = useLazyPasswordlessSignInConfirmQuery();

  useEffect(() => {
    if (!isUninitialized) {
      return;
    }

    if (!token) {
      navigate(ERoutes.signIn, {
        "replace": true
      });

      return;
    }

    let timer: number | null = null;

    confirmToken({ token }).then(({ data, isError }) => {
      if (isError || !data?.token) {
        navigate(ERoutes.signIn);

        return;
      }

      setTokenCookie?.(data.token);

      timer = window.setTimeout(() => {
        window.location.href = redirectionURL;
      }, 3000);
    });


    return () => {
      timer && window.clearTimeout(timer);
    };
  }, []);

  return <DataProcessingMessage />;
}
