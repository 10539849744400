import React from "react";
import { createPortal } from "react-dom";

import { TPortal } from "./Portal.types";

export default function Portal({
  children,
  targetId = `root`,
  targetEl,
}: TPortal) {
  return createPortal(
    children,
    targetEl || (document.getElementById(targetId) as HTMLElement)
  );
}
