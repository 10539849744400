import { TTranslationKeys } from "_providers/Translations/Translations";
import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import * as yup from "yup";

import {
  RE_PASSWORD_UPPERCASE,
  RE_USERNAME,
  RE_USERNAME_NO_CONSECUTIVE_PERIODS,
  RE_USERNAME_NO_PERIOND_AT_START_OR_END
} from "./rules";

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginFormResolver = () => yupResolver<yup.AnyObjectSchema>(
  yup.object({
    "email": yup
      .string()
      .min(
        5,
        i18next.t<string, TTranslationKeys>(`MSG_MUST-CONTAIN-AT-LEAST-CHARACTERS`, { "count": 5 })
      )
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`)),
    "password": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
  })
);

export const passwordlessLoginFormResolver = () => yupResolver<yup.AnyObjectSchema>(
  yup.object({
    "email": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .matches(emailRegExp, i18next.t<string, TTranslationKeys>(`INVALID_EMAIL`))
  })
);

export const signUpFormResolver = () => yupResolver<yup.AnyObjectSchema>(
  yup.object({
    "username": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .min(5)
      .max(20)
      .matches(
        RE_USERNAME,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      )
      .matches(
        RE_USERNAME_NO_PERIOND_AT_START_OR_END,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      )
      .matches(
        RE_USERNAME_NO_CONSECUTIVE_PERIODS,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      ),
    "email": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .matches(emailRegExp, i18next.t<string, TTranslationKeys>(`INVALID_EMAIL`)),
    "password": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .min(8)
      .matches(
        /[0-9]/,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      )
      .matches(
        RE_PASSWORD_UPPERCASE,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      ),
    "passwordConfirm": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .oneOf(
        [yup.ref(`password`), null],
        i18next.t<string, TTranslationKeys>(`PASSWORDS_DONT_MATCH`)
      )
  })
);

export const createPasswordFormResolver = () => yupResolver<yup.AnyObjectSchema>(
  yup.object({
    "password": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .min(8)
      .matches(
        /[0-9]/,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      )
      .matches(
        RE_PASSWORD_UPPERCASE,
        i18next.t<string, TTranslationKeys>(`MSG_PLEASE-ENTER-VALUE-THAT-MEETS-REQUIREMENTS`)
      ),
    "passwordConfirm": yup
      .string()
      .required(i18next.t<string, TTranslationKeys>(`MSG_PLEASE-FILL-THIS-FIELD`))
      .oneOf(
        [yup.ref(`password`), null],
        i18next.t<string, TTranslationKeys>(`PASSWORDS_DONT_MATCH`)
      )
  })
);
