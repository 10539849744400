const baseConfig: RequestInit = {
  "headers": {
    "Accept": `application/json`,
    "Content-Type": `application/json`
  }
};

export const getConfig: RequestInit = {
  ...baseConfig,
  "method": `GET`
};

export const patchConfig: RequestInit = {
  ...baseConfig,
  "method": `PATCH`
};

export const postConfig: RequestInit = {
  ...baseConfig,
  "method": `POST`
};

export const postMultiPartFormConfig: RequestInit = {
  "headers": {
    "Accept": `application/json`
  },
  "method": `POST`
};

export const patchMultiPartFormConfig: RequestInit = {
  "headers": {
    "Accept": `application/json`
  },
  "method": `PATCH`
};

export const deleteMultiPartFormConfig: RequestInit = {
  "headers": {
    "Accept": `application/json`
  },
  "method": `DELETE`
};

export const deleteConfig: RequestInit = {
  ...baseConfig,
  "method": `DELETE`
};
