import { Button, Dialog, SelfValidatedInput } from "_components";
import { TDialogMethods } from "_components/Dialog/Dialog";
import FormWrap from "_components/FormWrap";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { Form, Line, LinkBig, LinksWrap, LinkUsual } from "_styles";
import isEmpty from "lodash.isempty";
import React, { useContext, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDialogTitle } from "utils/common";
import { loginFormResolver } from "utils/resolvers";

import { useLazySignInQuery } from "./SignIn.queries";
import { Footer, MarkupWrap } from "./SignIn.styles";

export type TSignInFormData = {
  email: string;
  password: string;
};

export default function SignIn() {
  const [signInTrigger, { isLoading, isError, error }] = useLazySignInQuery();
  const navigate = useNavigate();
  const { setTokenCookie, redirectionURL = `` } = useContext(AppContext) || {};
  const dialogRef = useRef<TDialogMethods>(null);
  const { t } = useTranslation();

  const handleRedirect = (to: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(to);
  };
  const {
    handleSubmit,
    "formState": { "errors": formErrors },
    control,
    trigger
  } = useForm<TSignInFormData>({
    "defaultValues": {
      "email": ``,
      "password": ``
    },
    "resolver": loginFormResolver(),
    "reValidateMode": `onChange`
  });

  const onSubmit = ({ email, password }: TSignInFormData) => {
    signInTrigger({
      password,
      "redirect": redirectionURL,
      ...(email.includes(`@`) ? { email } : { "username": email })
    }).then(({ data, error }) => {
      const { token } = data || {};

      if (!error && token) {
        setTokenCookie?.(token);
        window.location.href = redirectionURL;
      }
    });
  };

  useEffect(() => {
    if (!isLoading && isError && error) {
      if (`data` in error) {
        const errorId = error.data.error.id;
        const isBackendError = error.status === 500;

        dialogRef.current?.open({
          "title": isBackendError ? t(`TITLE-SOMETHING_WENT_WRONG`) : getDialogTitle(errorId),
          "text": isBackendError ? t(`MSG-SOMETHING_WENT_WRONG`) : t(errorId)
        });
      }
    }
  }, [error, isError, isLoading, t]);

  return (
    <FormWrap>
      <MarkupWrap>
        <LinksWrap>
          <LinkBig
            className="active"
            href={ERoutes.signIn}
            onClick={handleRedirect(ERoutes.signIn)}
          >
            {t(`LBL_SIGN-IN`)}
          </LinkBig>

          <LinkBig href={ERoutes.signUp} onClick={handleRedirect(ERoutes.signUp)}>
            {t(`LBL_CREATE-ACCOUNT`)}
          </LinkBig>
        </LinksWrap>

        <Form noValidate onSubmit={handleSubmit(onSubmit)} id="login-form">
          <Controller
            name="email"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="name"
                placeholder={t(`MSG_ENTER-EMAIL-OR-USERNAME`)}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                label={t(`LBL_EMAIL-OR-USERNAME`)}
                errorMessage={error?.message}
                {...fieldProps}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
              <SelfValidatedInput
                id="password-input"
                type="password"
                placeholder={t(`LBL_YOUR-PASSWORD-PLACEHOLDER`)}
                label={t(`LBL_PASSWORD`)}
                onBlur={() => {
                  fieldProps.value && trigger(fieldProps.name);
                  onBlur?.();
                }}
                onChange={(value) => {
                  onChange(value.trim());
                }}
                errorMessage={error?.message}
                {...fieldProps}
              />
            )}
          />
        </Form>

        <LinkUsual onClick={handleRedirect(ERoutes.resetPassword)} href={ERoutes.resetPassword}>
          {t(`MSG_FORGOT-YOUR-PASSWORD`)}
        </LinkUsual>

        <Button
          form="login-form"
          size="medium"
          type="submit"
          fullWidth
          disabled={isLoading || !isEmpty(formErrors)}
          label={t(`LBL_SIGN-IN`)}
          loading={isLoading}
        />

        <Line>{t(`LBL_OR`)}</Line>
      </MarkupWrap>

      <Footer>
        <LinkBig
          className="simple"
          href={ERoutes.signUp}
          onClick={handleRedirect(ERoutes.passwordlessSignIn)}
        >
          {t(`LBL_SIGN-IN-WITHOUT-PASSWORD`)}
        </LinkBig>
      </Footer>

      <Dialog
        ref={dialogRef}
        actionButtonClick={() => {
          dialogRef.current?.close();
        }}
      />
    </FormWrap>
  );
}
