export default {
  "common": {
    "text": `#485164`,
    "disabled": `#b5b9bf`,
    "bg": `#e5e5e5`
  },
  "container": {
    "bg": `#fff`,
    "text": `#29282f`
  },
  "fonts": {
    "base": {
      "size": `14px`,
      "color": `#f00`,
      "lineHeight": `22px`,
      "weight": `400`
    },
    "header1": {
      "size": `14px`,
      "color": `#000`,
      "lineHeight": `22px`,
      "weight": `400`
    }
  },
  "backgroundColors": {
    "primary": `#f00f00`
  },
  "input": {
    "text": `#485164`,
    "borderFocused": `#3139eb`,
    "border": `#d1d5dc`,
    "bg": `#f6f6f9`,
    "placeholder": `#98a2b6`,
    "icon": `#98a2b6`,
    "plate": {
      "bg": `#f6f6f9`,
      "text": `#485164`,
      "icon": `#d1d5dc`,
      "pass": `#3139eb`
    },
    "error": `#d91e18`,
    "progress": {
      "bg": `#3139EB`
    }
  },
  "button": {
    "text": `#fff`,
    "textDisabled": `#485164`,
    "bg": `#1855F9`,
    "borderFocused": `#19A9FF`,
    "bgActive": `#19A9FF`,
    "bgDisabled": `#D1D5DC`,
    "outlined": {
      "borderFocused": `#3726F3`,
      "borderActive": `#19A9FF`,
      "text": `#2B2D33`,
      "textDisabled": `#D1D5DC`
    }
  },
  "dialog": {
    "bgBackdrop": `#2b2d33`,
    "bgDialog": `#515458`,
    "closeBtnColor": `#e7e7e7`,
    "titleColor": `#a3a7ae`,
    "textColor": `#f6f6f9`
  },
  "link": {
    "usual": {
      "text": `#3139EB`
    },
    "big": {
      "text": `#a3a7ae`,
      "simple": `#3139eb`
    }
  },
  "select": {
    "bgContainer": `#fff`,
    "bgList": `#FCFCFD`,

    "iconChevron": `#1855F9`,

    "borderList": `#D1D5DC`,

    "textOption": `#485164`
  }
};
