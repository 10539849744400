import { DataProcessingMessage } from "_components";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { useLazyConfirmUserQuery } from "_screens/SignUp/SignUp.queries";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

const CreateAccountConfirm = () => {
  const navigate = useNavigate();
  const { token, redirectionURL } = useContext(AppContext) || {};
  const [confirmUser] = useLazyConfirmUserQuery();

  useEffect(() => {
    if (!token) {
      navigate(ERoutes.signIn, {
        "replace": true
      });
    }

    if (token && redirectionURL) {
      confirmUser(token);

      const timer = window.setTimeout(() => {
        navigate(ERoutes.signIn, {
          "replace": true
        });
      }, 3000);

      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [confirmUser, navigate, redirectionURL, token]);

  return <DataProcessingMessage />;
};

export default CreateAccountConfirm;
