import { GroupBase, StylesConfig } from "react-select";
import { DefaultTheme } from "styled-components";

export const getSelectStyles = (
  theme: DefaultTheme
): StylesConfig<unknown, boolean, GroupBase<unknown>> => ({
  "dropdownIndicator": (baseStyles) => ({
    ...baseStyles,
    "color": theme.select.iconChevron,
    "&:hover": {
      "color": theme.select.iconChevron
    }
  }),
  "control": (baseStyles) => ({
    ...baseStyles,
    "border": 0,
    "outline": 0,
    "boxShadow": `none`,
    "backgroundColor": theme.select.bgContainer,
    "fontFamily": `interui`,
    "fontStyle": `normal`,
    "fontWeight": 500,
    "fontSize": 14,
    "lineHeight": `20px`
  }),

  "menu": (baseStyles) => ({
    ...baseStyles,

    "borderRadius": 6,
    "overflow": `hidden`,
    "border": `1px solid`,
    "borderColor": theme.select.borderList,
    "boxShadow": `0px 3px 6px rgba(95, 94, 94, 0.12)`
  }),

  "menuList": (baseStyles) => ({
    ...baseStyles,

    "padding": 0
  }),

  "option": (baseStyles) => ({
    ...baseStyles,

    "cursor": `pointer`,
    "color": theme.select.textOption,
    "backgroundColor": `transparent`,
    "fontFamily": `interui`,
    "fontStyle": `normal`,
    "fontWeight": 400,
    "fontSize": 14,
    "lineHeight": `20px`,

    "&:not(:last-child)": {
      "marginBottom": 4
    },

    "&:active": {
      "backgroundColor": `transparent`
    }
  })
});
