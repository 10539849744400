import { Line, LinkUsual, MarkupWrapBase, Text } from "_styles";
import styled from "styled-components";

export const MarkupWrap = styled(MarkupWrapBase)`
  min-height: 500px;

  ${Text} {
    margin: 0 0 24px;

    ${LinkUsual} {
      font-size: 12px;
      line-height: 25px;

      &:after {
        bottom: -1px;
      }
    }
  }

  ${Line} {
    margin: auto auto 30px;
  }
`;
