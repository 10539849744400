import styleProvider from "_providers/StyleProvider/StyleProvider.state";
import { signInAPI } from "_screens/SignIn/SignIn.queries";
import { signUpAPI } from "_screens/SignUp/SignUp.queries";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [signInAPI.reducerPath]: signInAPI.reducer,
    [signUpAPI.reducerPath]: signUpAPI.reducer,
    [styleProvider.name]: styleProvider.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(signInAPI.middleware)
    .concat(signUpAPI.middleware)
});

export type TState = ReturnType<typeof store.getState>;

export type TDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
