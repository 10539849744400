import { TTranslationKeys } from "_providers/Translations/Translations";
import { TLangCode } from "_types/common";
import i18n from "i18next";

const DASHBOARD_URL_LOCAL = `http://localhost:3000`;
const DASHBOARD_URL_DEVELOPMENT = `https://dashboard.development.estatelogs.com`;
const DASHBOARD_URL_PRERELEASE = `https://dashboard.prerelease.estatelogs.com`;
const DASHBOARD_URL_PRODUCTION = `https://dashboard.estatelogs.com`;

export const environment =
  (process.env.REACT_APP_ENV === `PRERELEASE` && `PRERELEASE`) ||
  (process.env.REACT_APP_ENV === `RELEASE` && `RELEASE`) ||
  (process.env.REACT_APP_ENV === `LOCAL` && `LOCAL`) ||
  `DEVELOPMENT`;

export const baseUrl =
  (environment === `PRERELEASE` && `https://prerelease.dev.estatelogs.com`) ||
  (environment === `RELEASE` && `https://1.api.estatelogs.com`) ||
  (environment === `DEVELOPMENT` && `https://development.dev.estatelogs.com`) ||
  (environment === `LOCAL` && `https://development.dev.estatelogs.com`) ||
  `https://dev.staging.estatelogs.com`;

export const DASHBOARD_REDIRECTION_URL =
  (environment === `PRERELEASE` && DASHBOARD_URL_PRERELEASE) ||
  (environment === `RELEASE` && DASHBOARD_URL_PRODUCTION) ||
  (environment === `DEVELOPMENT` && DASHBOARD_URL_DEVELOPMENT) ||
  (environment === `LOCAL` && DASHBOARD_URL_LOCAL) ||
  DASHBOARD_URL_LOCAL;

export function hexToRgba(hexCode: string, opacity = 1) {
  let hex = hexCode.replace(`#`, ``);

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${red},${green},${blue},${opacity})`;
}

const env = process.env.REACT_APP_ENV || `DEVELOPMENT`;

export const isDevEnv = env === `DEVELOPMENT`;

export const isLocalEnv = env === `LOCAL`;

export const getDialogTitle = (errorId: TTranslationKeys) => {
  switch (errorId) {
    case `INCORRECT_CREDS`:
      return i18n.t<string, TTranslationKeys>(`TITLE-INCORRECT_CREDS`);

    case `PASSWORDS_DONT_MATCH`:
      return i18n.t<string, TTranslationKeys>(`TITLE-INCORRECT_CREDS`);

    case `NOT_ACTIVATED`:
      return i18n.t<string, TTranslationKeys>(`TITLE-ACCOUNT_VERIFICATION`);

    default:
      return i18n.t<string, TTranslationKeys>(`LBL-ERROR`);
  }
};

export const classNames = (obj: Record<string, unknown>): string => {
  const keys: string[] = Object.keys(obj);

  return keys.filter((key) => !!obj[key]).join(` `);
};

export const getLangOptions = (): Array<{ value: TLangCode; label: string; }> => [
  { "value": `eng`, "label": i18n.t<string, TTranslationKeys>(`LBL_ENGLISH`) },
  { "value": `swe`, "label": i18n.t<string, TTranslationKeys>(`LBL_SWEDISH`) },
  // { value: `de`, label: i18n.t<string, TTranslationKeys>(`LBL_GERMAN`) },
  { "value": `fin`, "label": i18n.t<string, TTranslationKeys>(`LBL_FINNISH`) },
  { "value": `dan`, "label": i18n.t<string, TTranslationKeys>(`LBL_DANISH`) }
];
