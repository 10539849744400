import { AppContextProvider } from "_providers/AppContext";
import CreateAccountConfirm from "_screens/CreateAccountConfirm";
import CreatePassword from "_screens/CreatePassword";
import PasswordlessSignInConfirm from "_screens/PasswordlessSignInConfirm";
import ResetPassword from "_screens/ResetPassword";
import SignIn from "_screens/SignIn";
import PasswordlessSignIn from "_screens/SignInPasswordless";
import CreateAccount from "_screens/SignUp";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ERoutes } from "./RouterProvider.types";

export default function RouterProvider() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <Routes>
          <Route path={ERoutes.signIn} element={<SignIn />} />

          <Route path={ERoutes.signUp} element={<CreateAccount />} />

          <Route path={ERoutes.passwordlessSignIn} element={<PasswordlessSignIn />} />

          <Route path={ERoutes.resetPassword} element={<ResetPassword />} />

          <Route path={ERoutes.createPassword} element={<CreatePassword />} />

          <Route path={ERoutes.passwordlessSignInConfirm} element={<PasswordlessSignInConfirm />} />

          <Route path={ERoutes.signUpConfirm} element={<CreateAccountConfirm />} />

          <Route path="*" element={<Navigate to={ERoutes.signIn} replace />} />
        </Routes>
      </AppContextProvider>
    </BrowserRouter>
  );
}
