import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.button.bg};
  padding: 9px 17px;
  border-radius: 6px;
  color: ${({ theme }) => theme.button.text};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: none;
  cursor: pointer;

  &.full-width {
    display: block;
    width: 100%;
  }

  &:focus,
  &:focus-visible {
    border-color: ${({ theme }) => theme.button.borderFocused};
    outline: 0;
  }

  &:active {
    border-color: ${({ theme }) => theme.button.bgActive};
    background-color: ${({ theme }) => theme.button.bgActive};
  }

  &.small {
    padding: 2px 9px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.medium {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  &.big {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }

  &:disabled {
    color: ${({ theme }) => theme.button.textDisabled};
    border-color: ${({ theme }) => theme.button.bgDisabled};
    background-color: ${({ theme }) => theme.button.bgDisabled};
  }

  &.outline {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.button.outlined.text};

    &:focus {
      border-color: ${({ theme }) => theme.button.outlined.borderFocused};
    }

    &:active {
      border-color: ${({ theme }) => theme.button.outlined.borderActive};
    }

    &:disabled {
      color: ${({ theme }) => theme.button.outlined.textDisabled};
    }
  }

  &.loading {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
  }
`;
