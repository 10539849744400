import { useMemo } from "react";
import ReactSelect, { Props } from "react-select";
import { useTheme } from "styled-components";

import { getSelectStyles } from "./Select.utils";

export default function Select(props: Props) {
  const theme = useTheme();
  const styles = useMemo(() => getSelectStyles(theme), [theme]);

  return (
    <ReactSelect
      {...props}
      isSearchable={false}
      styles={styles}
      components={{
        IndicatorSeparator: null
      }}
    />
  );
}
