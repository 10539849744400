import translationDan from "_translations/translation_dan.json";
import translationDeu from "_translations/translation_deu.json";
import translationEng from "_translations/translation_eng.json";
import translationFin from "_translations/translation_fin.json";
import translationSwe from "_translations/translation_swe.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React, { ReactElement } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";

import { LNG_TAG_TO_ISO_639_2 } from "./Translations.constants";

export const SUPPORTED_LANGUAGES = [`eng`, `swe`, `fin`, `deu`, `dan`] as const;

export type TTranslationKeys = keyof typeof translationEng;

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  "name": `navigatorDetector`,
  lookup() {
    const lng = navigator.language;

    return LNG_TAG_TO_ISO_639_2[lng] || `eng`;
  }
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    "fallbackLng": `eng`,
    "supportedLngs": SUPPORTED_LANGUAGES,
    "interpolation": { "escapeValue": false },
    "keySeparator": false,
    "detection": {
      "order": [`localStorage`, `navigatorDetector`],
      "lookupLocalStorage": `lng`,
      "caches": [`localStorage`]
    },
    "resources": {
      "deu": { "translation": translationDeu },
      "eng": { "translation": translationEng },
      "fin": { "translation": translationFin },
      "swe": { "translation": translationSwe },
      "dan": { "translation": translationDan }
    },
    "react": {
      "bindI18nStore": `added`
    }
  });

interface ITranslationsProvider {
  children: React.ReactElement;
}

export default function TranslationsProvider({ children }: ITranslationsProvider): ReactElement {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
