import { EstateLogsLogo, RedirectionSpinner } from "_components/Icons";
import { ConfirmationScreenBackdrop, Text } from "_styles";
import { useTranslation } from "react-i18next";

const DataProcessingMessage = () => {
  const { t } = useTranslation();

  return (
    <ConfirmationScreenBackdrop>
      <RedirectionSpinner />

      <div
        style={{
          "display": `flex`,
          "gap": 22,
          "alignItems": `center`,
          "margin": `24px 0 16px`
        }}
      >
        <EstateLogsLogo size={38} />

        <h1>{t(`LBL_ESTATE-LOGS`)}</h1>
      </div>

      <Text>
        {t(`MSG_WAIT-DATA-PROCESSING`)}

        <br />

        {t(`MSG_PROCESS-MAY-TAKE-FEW-SECONDS`)}...
      </Text>
    </ConfirmationScreenBackdrop>
  );
};

export default DataProcessingMessage;
