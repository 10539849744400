import styled from "styled-components";

export const Backdrop = styled.div`
  @keyframes backdrop-fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes backdrop-fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes dialog-fadein {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    70% {
      opacity: 1;
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes dialog-fadeout {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    40% {
      transform: scale(1.02);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  &.hide {
    animation: backdrop-fadeout ease-out 260ms;
    opacity: 0;
  }

  animation: backdrop-fadein ease-in 130ms forwards;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => `${theme.dialog.bgBackdrop}b3`};
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-close {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 25px;
    right: 19px;
    color: ${({ theme }) => theme.dialog.closeBtnColor};
    cursor: pointer;
  }

  header {
    position: relative;
    padding: 56px 0 14px;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    color: ${({ theme }) => theme.dialog.titleColor};

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      margin: 0;
      padding: 0;
      width: 100%;
      padding: 0 50px;
      text-align: center;
    }
  }

  & > div {
    flex-basis: min-content;
    min-width: 320px;
    min-height: 320px;
    opacity: 0;
    background-color: yellow;
    animation: 200ms dialog-fadein ease-in 130ms forwards;
    background: ${({ theme }) => theme.dialog.bgDialog};
    box-shadow: 0px 5px 12px rgba(14, 15, 20, 0.49);
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 52px;

    main {
      display: block;
      padding: 0px 50px;
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: ${({ theme }) => theme.dialog.textColor};
    }

    footer {
      padding-top: 24px;
      max-width: 220px;
      margin: auto;
    }

    &.hide {
      animation: dialog-fadeout ease 260ms;
    }
  }
`;
