// Locale tags to ISO 639-2 ID
// For Swedish, Danish, Finnish
export const LNG_TAG_TO_ISO_639_2: Record<string, string> = {
  "sv": `swe`,
  "sv-AX": `swe`,
  "sv-FI": `swe`,
  "sv-SE": `swe`,
  "da": `dan`,
  "da-DK": `dan`,
  "da-GL": `dan`,
  "fi": `fin`,
  "fi-FI": `fin`
};
