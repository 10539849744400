import { Button, ChevronLeft, Dialog, SelfValidatedInput } from "_components";
import { TDialogMethods } from "_components/Dialog/Dialog";
import FormWrap from "_components/FormWrap";
import { AppContext } from "_providers";
import { ERoutes } from "_providers/RouterProvider/RouterProvider.types";
import { useLazyPasswordlessSignInQuery } from "_screens/SignIn/SignIn.queries";
import { Form, Line, LinkBig, LinksWrap, Text } from "_styles";
import { TRANSLATION_COMPONENTS } from "_utils/constants";
import isEmpty from "lodash.isempty";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getDialogTitle, isDevEnv } from "utils/common";
import { passwordlessLoginFormResolver } from "utils/resolvers";

import { MarkupWrap } from "./SignInPasswordless.styles";

type TPasswordlessSignInFormData = {
  email: string;
};

export default function SignInPasswordless() {
  const dialogRef = useRef<TDialogMethods>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { redirectionURL = ``, language = `eng` } = useContext(AppContext) || {};
  const [passwordlessSignInTrigger, { isLoading, isSuccess, isFetching, error, isError }] =
    useLazyPasswordlessSignInQuery();
  const [emailCached, setEmailCached] = useState<string | null>(null);

  const isEmailSent = emailCached && isSuccess;

  const {
    handleSubmit,
    "formState": { "errors": formErrors },
    control,
    trigger
  } = useForm<TPasswordlessSignInFormData>({
    "defaultValues": {
      "email": ``
    },
    "resolver": passwordlessLoginFormResolver()
  });

  const handleRedirect = useCallback(
    (to: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      navigate(to);
    },
    [navigate]
  );

  const sendEmail = useCallback(
    (email: string) => {
      passwordlessSignInTrigger({ email, "redirect": redirectionURL, language }, false);
    },
    [language, passwordlessSignInTrigger, redirectionURL]
  );

  const onSubmit = handleSubmit(({ email }) => {
    setEmailCached(email);

    sendEmail(email);
  });

  useEffect(() => {
    if (!isLoading && isError && error) {
      if (`data` in error) {
        const errorId = error.data.error.id;
        const isBackendError = error.status === 500;

        dialogRef.current?.open({
          "title":
            isBackendError || !isDevEnv ? t(`TITLE-SOMETHING_WENT_WRONG`) : getDialogTitle(errorId),
          "text": isBackendError || !isDevEnv ? t(`MSG-SOMETHING_WENT_WRONG`) : t(errorId)
        });
      }
    }
  }, [error, isError, isLoading, t]);

  return (
    <>
      <FormWrap>
        {!isEmailSent && (
          <MarkupWrap>
            <LinksWrap>
              <LinkBig
                className="active"
                href={ERoutes.passwordlessSignIn}
                onClick={handleRedirect(ERoutes.passwordlessSignIn)}
              >
                {t(`LBL_SIGN-IN`)}
              </LinkBig>

              <LinkBig href={ERoutes.signUp} onClick={handleRedirect(ERoutes.signUp)}>
                {t(`LBL_CREATE-ACCOUNT`)}
              </LinkBig>
            </LinksWrap>

            <Text style={{ "fontSize": 16, "marginBottom": 40 }}>
              {t(`MSG_ENTER-EMAIL-PASSWORDLESS-SIGN-IN`)}
            </Text>

            <Form onSubmit={onSubmit} id="login-passwordless-form">
              <Controller
                name="email"
                control={control}
                render={({ "field": { onBlur, onChange, ...fieldProps }, "fieldState": { error } }) => (
                  <SelfValidatedInput
                    disabled={isLoading || isFetching}
                    id="email"
                    placeholder={t(`MSG_ENTER-YOUR-EMAIL`)}
                    onBlur={() => {
                      fieldProps.value && trigger(fieldProps.name);
                      onBlur?.();
                    }}
                    label={t(`LBL_EMAIL`)}
                    errorMessage={error?.message}
                    onChange={(value) => {
                      onChange(value.trim());
                    }}
                    {...fieldProps}
                  />
                )}
              />
            </Form>

            <Button
              form="login-passwordless-form"
              size="medium"
              type="submit"
              fullWidth
              onClick={onSubmit}
              disabled={isLoading || isFetching || !isEmpty(formErrors)}
              label={t(`MSG_SEND-ME-A-LINK`)}
              loadingLabel={t(`LBL_SENDING`)}
              loading={isLoading || isFetching}
              style={{ "marginTop": 32 }}
            />

            <Line className="solid" />

            <Link className="lnk-back" to={ERoutes.signIn}>
              <ChevronLeft />

              {t(`LBL_BACK-TO-QUICK-ENTRY`)}
            </Link>
          </MarkupWrap>
        )}

        {isEmailSent && (
          <MarkupWrap>
            <Text>
              <Trans
                i18nKey="MSG_WE-SENT-YOU-EMAIL-PASSWORDLESS-SIGN-IN"
                values={{ "email": emailCached }}
                components={TRANSLATION_COMPONENTS}
              />

              <br />

              {t(`MSG_DID-NOT-GET-LINK-EMAIL`)}
            </Text>

            <Button
              size="big"
              fullWidth
              onClick={() => {
                sendEmail(emailCached);
              }}
              disabled={isLoading || isFetching || !isEmpty(formErrors)}
              label={t(`LBL_RESEND-ME-A-LINK`)}
              loadingLabel={t(`LBL_SENDING`)}
              loading={isLoading || isFetching}
            />

            <Line />

            <Link className="lnk-back" to={ERoutes.signIn}>
              <ChevronLeft />

              {t(`LBL_BACK-TO-QUICK-ENTRY`)}
            </Link>
          </MarkupWrap>
        )}
      </FormWrap>

      <Dialog
        ref={dialogRef}
        actionButtonClick={() => {
          dialogRef.current?.close();
        }}
      />
    </>
  );
}
