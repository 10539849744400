import styled from "styled-components";

const PlateBase = styled.div`
  display: none;
  position: absolute;
  background-color: #2b2d33;
  border-radius: 6px;
  width: calc(100% + 4px);
  left: -2px;
  top: calc(100% + 10px);
  box-shadow: 0px 5px 12px rgba(14, 15, 20, 0.49);
  z-index: 5;

  svg {
    flex-shrink: 0;
  }
`;

export const ValidationProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 12px;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.input.text};
  }

  progress {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.input.progress.bg};
    border: 0;
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    flex-grow: 1;

    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.input.progress.bg};
      border-radius: 4px;
    }

    &::-moz-progress-bar {
      background-color: ${({ theme }) => theme.input.progress.bg};
      border-radius: 4px;
      transition: width 0.1s ease-in-out;
    }

    &::-webkit-progress-value {
      background-color: ${({ theme }) => theme.input.progress.bg};
      border-radius: 4px;
      transition: width 0.1s ease-in-out;
    }

    height: 8px;
  }
`;

export const ValidationPlate = styled(PlateBase)`
  display: none;
  z-index: 6;
  background-color: ${({ theme }) => theme.input.plate.bg};

  ul {
    padding: 12px 12px 0 12px;
    list-style: none;
    margin: 0;

    &:only-child {
      margin-bottom: 16px;
    }
  }

  li {
    display: flex;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.input.plate.text};
    margin-bottom: 8px;
    transition: color 0.1s ease-in-out;
    svg {
      color: ${({ theme }) => theme.input.plate.icon};
    }
    &.pass {
      color: ${({ theme }) => theme.input.plate.pass};
      svg {
        color: ${({ theme }) => theme.input.plate.pass};
      }
    }
  }
`;

export const ErrorPlate = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.input.error};
  gap: 4px;
  margin-top: 6px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.input.text};
`;

export const Container = styled.div`
  position: relative;
  height: 40px;
  background: ${({ theme }) => theme.input.bg};
  border: 1px solid ${({ theme }) => theme.input.border};
  border-radius: 6px;
  margin-top: 2px;

  &.focused {
    border-color: ${({ theme }) => theme.input.borderFocused};
  }

  &.error:not(.focused) {
    border-color: ${({ theme }) => theme.input.error};
  }

  &.empty {
    padding: 0;
  }

  button {
    color: ${({ theme }) => theme.input.placeholder};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    border: 0px;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    display: block;
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.input.plate.text};
    background-color: transparent !important;
    border: 0;
    padding-left: 16px;
    border-radius: 6px;


    &:autofill {
      -webkit-text-fill-color: ${({ theme }) => theme.input.text};
      -webkit-box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.input.bg} inset`};
      box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.input.bg} inset`};
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.input.placeholder};
    }

    &:active,
    &:focus {
      border: 0;
      outline: 0;
    }

    &:focus + ${ValidationPlate} {
      display: block;
    }

    &:focus ~ ${ErrorPlate} {
      display: flex;
    }

    &:disabled {
      opacity: 0.8;
    }
  }
`;
